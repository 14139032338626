import axios from 'axios/index';
import _ from 'lodash';
import { Environment, getCurrentEnvironment } from '@archinsurance-viki/property-jslib/src/utils/environment-helpers';

const isTest = process.env.NODE_ENV === 'test';
const environment = isTest ? Environment.Local : getCurrentEnvironment();

function initBaseUrl() {
    let baseUrl = '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

    let ventusUrl = '';

    const ecsRegExp = /:\/\/viki-([^/]+)-ecs.ventusrisk/.exec(window.location.href);
    const ecsEnvironment = ecsRegExp && ecsRegExp[1] ? ecsRegExp[1] : null; // for unit tests

    if (ecsEnvironment) {
        ventusUrl = '//vmac-' + ecsEnvironment + '-ecs.ventusrisk.com';
    } else {
        if (environment === Environment.Production) {
            ventusUrl = '//viki.ventusrisk.com';
        } else if (environment === Environment.Uat || environment === Environment.Catdev) {
            ventusUrl = `//viki-${environment}.ventusrisk.net`;
        } else {
            ventusUrl = `//viki-${environment}.ventusrisk.com`;
        }
    }
    return [baseUrl, ventusUrl];
}

export const [BASEURL, VENTUS_URL] = isTest ? ['//vmac-local.ventusrisk.com:8001', '//viki-local.ventusrisk.com'] : initBaseUrl();
console.assert(BASEURL);
console.assert(VENTUS_URL);

export const __API = axios.create({
    withCredentials: true,
    headers: { Accept: 'application/json' },
    xsrfCookieName: `csrftoken-${environment}`,
    xsrfHeaderName: 'X-CSRFToken',
    baseURL: `${BASEURL}/api/v1/`,
});

__API.interceptors.request.use(config => {
    const authToken = localStorage.getItem('accessToken');
    if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
});

export const __API2 = axios.create({
    withCredentials: true,
    headers: { Accept: 'application/json' },
    xsrfCookieName: `csrftoken-${environment}`,
    xsrfHeaderName: 'X-CSRFToken',
    baseURL: `${BASEURL}/api/v1/`,
});

__API2.interceptors.request.use(config => {
    const authToken = localStorage.getItem('accessToken');
    if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
});

export const endpoints = {
    arch: {
        getAccountConflicts: (options: any) => {
            return __API.get(`arch_account_conflicts/${options.submissionId}`, { params: options.params });
        },
        getSubmissionConflicts: (options: any) => {
            return __API.get(`arch_submission_conflicts/${options.submissionId}`, { params: options.params });
        },
        resolveAccountConflict: (options: any) => {
            return __API.get(`resolve_account_conflict/${options.submissionId}`, { params: options.params });
        },
        submissionOveride: (options: any) => {
            return __API.get(`arch_submission_override/${options.submissionId}`, { params: options.params });
        },
        getStatuses: (options: any) => {
            return __API.get(`arch_status/${options.accountId}`, { params: options.params });
        },
        retrySubmissionWithArch: (options: any) => {
            return __API.get(`retry_arch_submission/${options.submissionId}`, { params: options.params });
        },
        searchArchSubmissions: (options: any) => {
            return __API.get(`search_arch_submissions`, { params: options.params });
        },
    },

    ocr: {
        image: (options: any) => {
            return __API.post(`ocr`, options.params);
        },
    },

    fake_data: {
        create_smms_submission: (options: any) => {
            return __API.post(`fake-data/create-fake-submission`, options.params);
        },
    },
    classify_selected_text: {
        post: (options: any) => {
            return __API.post(`classify_selected_text/`, options.params);
        },
    },
    submission: {
        get: (options: any) => {
            return __API.get(
                (options.customQueryParams
                    ? [`submission/${options.id}/`].concat(options.customQueryParams)
                    : [`submission/${options.id}/`, 'documents.*', 'arch_account.*', 'buildings.*']
                )
                    .join('&include[]=')
                    .replace(/&/, '?') + '&filter{documents|is_deleted}=0&filter{buildings|is_deleted}=0',
                {
                    params: options.params,
                }
            );
        },
        selectNewClient: (options: any) => {
            return __API.put(`submission/${options.submissionId}/select_new_client/`, options.params);
        },
        changeStatus: (options: any) => {
            return __API.put(`submission/${options.submissionId}/take_action/`, options.params);
        },
        setOverrideReason: (options: any) => {
            return __API.put(`submission/${options.submissionId}/set_override_reason/`, options.params);
        },
        downloadDocuments: (options: any) => {
            return __API.get(`submission/${options.id}/download_documents/`);
        },
        getInsuredReferences: (options: any) => {
            return __API.get(`submission/${options.id}/insured_references/`);
        },
        saveDocumentData: (options: any) => {
            return __API.patch(`document/${options.id}/`, options.params);
        },
        getBuildDistributions: (options: any) => {
            return __API.get(`building_distribution?submission_id=${options.submission_id}`);
        },
        getDuplicates: (options: any) => {
            return __API.get(`duplicate_submission?submission_id=${options.submission_id}`);
        },
        getDeltaRecords: (options: any) => {
            return __API.get(`delta_record/?submission_id=${options.submission_id}`);
        },
        getImageRightLogs: (options: any) => {
            return __API.get(`imageright_request/?submission_id=${options.submission_id}`);
        },
        runSovBot: (options: any) => {
            return __API.post(`run_sov_bot/`, options.params);
        },
        selectArchAccount: (options: any) => {
            return __API.put(`submission/${options.params.submissionId}/select_arch_account/`, options.params);
        },
        deselectArchAccount: (options: any) => {
            return __API.put(`submission/${options.params.submissionId}/deselect_arch_account/`, options.params);
        },
        runSovBotWithDocumentId: (options: any) => {
            return __API.post(`run_sov_bot/`, options.params);
        },
        overrideAutoAction: (options: any) => {
            return __API.put(`submission/${options.params.submissionId}/override_auto_action/`, options.params);
        },
        selectProducer: (options: any) => {
            return __API.put(`submission/${options.submissionId}/select_arch_producer/`, options.params);
        },
        linkToSubmission: (options: any) => {
            return __API.put(`submission/${options.submissionId}/link_to_submission/`, options.params);
        },
        unlinkFromSubmission: (options: any) => {
            return __API.post(`submission/${options.submissionId}/unlink_from_submission/`, options.params);
        },
    },
    submission_data: {
        search: (options: Record<string, any>) =>
            __API.get('submission_data', {
                params: options.params,
            }),
    },
    insured: {
        getFuzzy: function (options: any) {
            let params: any = {};
            params['name__fuzzy'] = options.params.searchString;
            params['submission_id'] = options.params.submissionId;

            if (options.params.limit) {
                params.limit = options.params.limit;
            }

            return __API
                .get('insured/', {
                    params,
                })
                .then(({ data }) => (data as any).insureds);
        },
        create: function (name: string, submission_id: number) {
            return __API.post('insured/', { name, submission_id }).then(({ data: { insured } }: { data: any }) => insured);
        },
        getStateFromZipcode: function (params: any) {
            return __API.get(`helpers/get_state_from_zipcode?zip_code=${params.zipCode}&submissionId=${params.submissionId}`);
        },
    },
    archAccount: {
        search: function (options: any) {
            const { name, street, fein, state } = options.params;
            const submission_id = options.params.submissionId;
            const params: any = { name, street, fein, state, submission_id };
            return __API.get('arch_account/search_arch', { params }).then(({ data }) => data);
        },
        alternativeNames: function (options: any) {
            return __API.get(`arch_account/alternate_names`, options).then(({ data }) => data);
        },

        create_or_update_account: function (options: any) {
            return __API.post(`arch_account/create_or_update_account/`, options.params);
        },
    },
    pagedTable: {
        // getAccountConflicts: (options: any) => {
        //     return __API.get(`arch_account_conflicts/${options.accountId}`, { params: options.params });
        // },
        downloadTableAsCSV(options: Record<string, any>) {
            if (options.tableConfig.ignoreFieldsForExport) {
                options.params.fields = _.filter(options.params.fields.split(','), x => {
                    return options.tableConfig.ignoreFieldsForExport.indexOf(x) === -1;
                }).join(',');
            }
            options.params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            return __API.get(`${options.tableConfig.apiEndpoint}/download/`, {
                params: options.params,
            });
        },
    },
    producingAgent: {
        getFuzzy: function (options) {
            let params: any = {};
            params['name__fuzzy'] = options.params.searchString;
            params['sub_division_id'] = options.params.subDivisionId;
            if (options.params.limit) {
                params.limit = options.params.limit;
            }

            return __API
                .get('producing_agent/', {
                    params,
                })
                .then(({ data }) => (data as any).producers);
        },
        create: function (options: any) {
            return __API
                .post('producing_agent/', { submission_id: options.submissionId, ...options.payload })
                .then(({ data }) => (data as any).producing_agent);
        },
    },
    producer: {
        getFuzzy: function (options: string | Record<string, any>) {
            let params: any = {};

            // shared library fuzzy input components sends in a string
            // but when we call API.doRequest we send in an object
            if (typeof options === 'string') {
                params['search_input'] = options;
            } else {
                params['search_input'] = options.params.searchString;
                params['site_id'] = options.params.siteId;
                params['inbox_id'] = options.params.inboxId;
                params['state'] = options.params.state;
            }

            return __API
                .get('producer/', {
                    params,
                })
                .then(({ data }) => (data as any).results);
        },
    },
    subDivision: {
        getFuzzy: function (searchString: string) {
            let params: any = {};
            params['name__fuzzy'] = searchString;
            return __API
                .get('subdivision/', {
                    params,
                })
                .then(({ data }) => (data as any).results);
        },
    },
    inbox: {
        get: () => {
            return __API.get('inbox/');
        },
        resendEmail: (options: Record<string, any>) => {
            return __API.get(`email/resend/${options.emailId}`, options);
        },
        sendEmail: (options: any) => {
            return __API.post(`email/template/${options.params.action}/${options.params.submissionId}`, options.params);
        },
        getTemplate: (options: any) => {
            return __API.get(`email/template/${options.params.action}/${options.params.submissionId}`, options);
        },
        putTemplate: (options: any) => {
            return __API.put(`email/template/${options.params.action}/${options.params.submissionId}/`, options.params);
        },
        getReplyTemplates: (options: any) => {
            return __API.get(`email/template/${options.params.submissionId}/reply_templates/`, options.params);
        },
        getForwardTemplates: (options: any) => {
            return __API.get(`email/template/${options.params.submissionId}/forward_templates/`, options.params);
        },
        fwdToViki: (options: any) => {
            return __API.post(`email/fwd`, options.params);
        },
        getFuzzy: (searchString: string) => {
            let params: any = {};
            params['email__fuzzy'] = searchString;
            return __API.get(`email/search`, { params: params }).then(({ data }) => (data as any).emails);
        },
    },
    note: {
        get: (options: any) => {
            return __API.get('note/', options);
        },
        create: (options: any) => {
            return __API.post('note/', options.params);
        },
        update: (options: any) => {
            return __API.patch(`note/${options.noteId}/`, options.params);
        },
    },
    table: {
        getGlossarys: (options: any) => {
            let tableParams = options.params.tables.join();
            return __API.get(`glossary/?tables=${tableParams}`, {});
        },
    },
};

export const staticMethods = {
    get: function (url: string) {
        return __API.request({ url: url, baseURL: BASEURL });
    },
};
